<template>
	<section>
		<b-form>
			<div class="table-responsive">
				<table class="content-table table table-bordered text-center w-100">
					<thead>
						<tr>
							<th
								class="py-2"
								colspan="14"
							>CATEGORÍAS REQUERIDAS PARA LA PROPUESTA TÉCNICA</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(document, index) in tecDocuments"
							:key="index"
						>
							<td>
								<label>{{ index + 1 }}</label>
							</td>
							<td
								colspan="3"
								class="text-left"
							>
								<label>{{ document.name }}</label>
							</td>
							<td>
								<b-form-radio
									v-model="document.proposal_option_id"
									:name="'tec-radios-' + index"
									:value="1"
									disabled
									readonly
								>SÍ</b-form-radio>
							</td>
							<td>
								<b-form-radio
									v-model="document.proposal_option_id"
									:name="'tec-radios-' + index"
									:value="2"
									disabled
									readonly
								>PARCIAL</b-form-radio>
							</td>
							<td>
								<b-form-radio
									v-model="document.proposal_option_id"
									:name="'tec-radios-' + index"
									:value="3"
									disabled
									readonly
								>NO</b-form-radio>
							</td>
							<td>
								<b-form-radio
									v-model="document.proposal_option_id"
									:name="'tec-radios-' + index"
									:value="4"
									disabled
									readonly
								>N.A.</b-form-radio>
							</td>
							<td colspan="2">
								<v-select
									id="rating"
									class="mx-auto"
									style="width: 180px !important;"
									v-model="document.proposal_rating"
									:options="options.proposalRatings"
									append-to-body
									placeholder="Seleccione"
									:disabled="isDisabled || !isAssigned"
									:readonly="isDisabled || !isAssigned"
								/>
							</td>
							<td>
								<b-button-upload
									entity="COMPETITIONS"
									:entity-id="competitionId"
									:is-disabled="true"
									:competition-props="{ isSigTechnical: true, ownerId: document.id }"
									:modal-props="{ title: 'DOCUMENTOS DE PROPUESTA TÉCNICA' }"
									:button-props="{ id: `tooltip-doc-${document.id}`, onlyIcon: true }"
								/>
								<b-tooltip
									:target="`tooltip-doc-${document.id}`"
									triggers="hover"
									placement="left"
									no-fade
								>
									<div>Ver</div>documentos
								</b-tooltip>
							</td>
							<td>
								<b-button
									:id="`tooltip-bidder-comment-${document.id}`"
									v-ripple.400="'rgba(113, 102, 240, 0.15)'"
									variant="outline-primary"
									class="btn-icon rounded-circle"
									@click="openCommentModal(document.id)"
								>
									<feather-icon icon="MessageSquareIcon" />
								</b-button>
								<b-tooltip
									:target="`tooltip-bidder-comment-${document.id}`"
									triggers="hover"
									placement="right"
									no-fade
								>
									<div>Comentario</div>del postor
								</b-tooltip>
							</td>
							<td>
								<ButtonReview
									@update-status="value => updateStatus(index, value)"
									:statusReview="document.reviewed"
									:disabled="isDisabled || !isAssigned"
								/>
							</td>
							<td>
								<b-button
									:id="`tooltip-reviewed-comment-${document.id}`"
									v-ripple.400="'rgba(113, 102, 240, 0.15)'"
									variant="outline-primary"
									class="btn-icon rounded-circle"
									@click="openCommentModal(document.id, true)"
								>
									<feather-icon icon="MessageSquareIcon" />
								</b-button>
								<b-tooltip
									:target="`tooltip-reviewed-comment-${document.id}`"
									triggers="hover"
									placement="left"
									no-fade
								>
									<div>Comentario</div>del revisor
								</b-tooltip>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<br />
			<b-button-loading
				v-if="!isDisabled && isAssigned"
				text="GUARDAR CAMBIOS"
				type="submit"
				variant="outline-primary"
				:block="true"
				@process-action="saveInformation"
				:processing="processing"
				:disabled="isDisabled"
			/>
		</b-form>

		<b-modal
			ref="refCommentModal"
			title-tag="div"
			header-bg-variant="primary"
			header-text-variant="white"
			centered
			:title="modalTitle"
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
		>
			<b-form>
				<b-col
					cols="12"
					class="my-2"
				>
					<b-form-group>
						<b-form-textarea
							id="comment"
							v-model="comment"
							placeholder="Comente aquí ..."
							:disabled="isDisabled || !updating || !isAssigned"
							:readonly="isDisabled || !updating || !isAssigned"
							rows="4"
						/>
					</b-form-group>
				</b-col>
			</b-form>

			<template #modal-footer>
				<b-button
					v-if="updating && isAssigned && !isDisabled"
					@click="saveInformation"
					variant="primary"
					:disabled="isDisabled"
				>GUARDAR</b-button>

				<b-button
					v-ripple.400="'rgba(186, 191, 199, 0.15)'"
					type="button"
					variant="outline-secondary"
					class="ml-2"
					@click="closeCommentModal"
				>CERRAR</b-button>
			</template>
		</b-modal>
	</section>
</template>

<script>
import { ref, onBeforeMount } from "@vue/composition-api"
import BCardCountdown from "@core/components/b-card-countdown/BCardCountdown"
import BButtonUpload from "@core/components/b-button-upload/BButtonUpload.vue"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import BIconFile from "@core/components/b-icon-file/BIconFile.vue"
import ButtonReview from "@core/components/button-review/ButtonReview.vue"
import Ripple from "vue-ripple-directive"
import { VBModal, VBTooltip } from "bootstrap-vue"
import axios from "@axios"
import vSelect from "vue-select"
import useNotifications from "@notifications"

export default {
	components: {
		BCardCountdown,
		BButtonLoading,
		BButtonUpload,
		BIconFile,
		ButtonReview,
		vSelect
	},
	directives: {
		"b-modal": VBModal,
		"b-tooltip": VBTooltip,
		Ripple
	},
	props: {
		tenderId: {
			type: Number,
			required: true
		},
		competitionReviewId: {
			type: Number,
			required: false
		},
		competitionId: {
			type: Number,
			required: true
		},
		proposalsData: {
			type: Array,
			default: () => [],
			required: true
		},
		isDisabled: {
			type: Boolean,
			required: true
		},
		isAssigned: {
			type: Boolean,
			required: true
		}
	},
	setup(props) {
		const { toastNotification } = useNotifications()

		const tenderId = ref(props.tenderId)
		const competitionId = ref(props.competitionId)
		const proposalsData = ref(props.proposalsData)

		const processing = ref(false)

		const refCommentModal = ref(null)
		const comment = ref(null)
		const options = ref({})

		const TEC_ID = 3
		const tecDocuments = ref([])
		const proposalId = ref(null)
		const updating = ref(false)
		const modalTitle = ref("COMENTARIO DEL POSTOR")

		onBeforeMount(async () => {
			await getSelectFilters()
			setData()
		})

		const getSelectFilters = async () => {
			const response1 = await axios.get("/selectors/proposal-ratings")
			options.value = {
				...response1.data
			}
		}

		const setData = () => {
			tecDocuments.value = proposalsData.value
				.filter((item) => parseInt(item.proposal_type_id) === TEC_ID)
				.map((item) => ({
					//id: item.id,
					id: item.sig_technical_proposal.id,
					name: item.sig_technical_proposal.name,
					proposal_option_id: item.sig_technical_proposal.proposal_option_id,
					comment: item.sig_technical_proposal.comment,
					proposal_rating: item.proposal_rating || null,
					score: item.score,
					reviewed: item.status ? 1 : 0,
					comment_reviewed: item.comment
				}))
		}

		const openCommentModal = (id, isReviewed = false) => {
			const document = tecDocuments.value.find((item) => item.id === id)
			proposalId.value = id

			proposalId.value = id
			comment.value = document.comment
			modalTitle.value = "COMENTARIO DEL POSTOR"
			updating.value = false

			if (isReviewed) {
				comment.value = document.comment_reviewed
				modalTitle.value = "COMENTARIO DEL REVISOR"
				updating.value = true
			}

			refCommentModal.value.show()
		}

		const resetModal = () => {
			proposalId.value = null
			comment.value = null
			updating.value = false
		}

		const closeCommentModal = () => {
			resetModal()
			refCommentModal.value.hide()
		}

		const updateStatus = (index, value) => {
			tecDocuments.value[index].reviewed = value
		}

		const saveInformation = async () => {
			try {
				processing.value = true

				let payload = {
					tender_id: tenderId.value,
					competition_id: competitionId.value
				}

				if (updating.value) {
					payload.proposal_id = proposalId.value
					payload.comment_reviewed = comment.value
				} else {
					const selectedOptions = tecDocuments.value.map((doc) => ({
						id: doc.id,
						proposal_rating_id: doc.proposal_rating
							? doc.proposal_rating.value
							: null,
						reviewed: doc.reviewed
					}))

					payload.selectedOptions = selectedOptions
				}

				const response = await axios.put(
					"/competition-sig-technical-reviews/",
					payload
				)
				if (updating.value) {
					tecDocuments.value.forEach((doc) => {
						if (doc.id === proposalId.value) {
							doc.comment_reviewed = comment.value
						}
					})
					closeCommentModal()
				}

				toastNotification(response.data.message)
			} catch (error) {
				toastNotification(error.response.data.message, false)
			} finally {
				processing.value = false
			}
		}

		return {
			refCommentModal,
			processing,
			updating,
			comment,
			tecDocuments,
			modalTitle,
			options,

			openCommentModal,
			closeCommentModal,
			saveInformation,
			updateStatus
		}
	}
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>